.userdata {
  display: flex;
  flex-direction: row;
}

.card-wrapper {
  height: 85vh; // prevents scroll for now, but should be fixed
  width: 100vw;
  background: #1c1f28;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 36px;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.46;
    text-align: center;
    color: #ffffff;
    width: 386px;
  }
}

.login {
  * {
    box-sizing: border-box;
  }

  h1 {
    color: #21273b;
  }

  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .face-container {
    h1 {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      width: 295px;
      margin: 0 auto;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  .error-message {
    font-size: 30px;
    font-weight: bold;
    color: #ff5c6f;
    margin-top: 30px;
    text-align: center;
  }

  .camera-oval-outer {
    width: 365px;
    height: 365px;
    margin: 0 auto;
    border: solid 5.6px #21273b;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &.no-borders {
      border: none;
    }

    &.has-error {
      border-color: #ff5c6f;
    }

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .borders {
      position: absolute;
      left: 0;
      right: 0;
      height: 400px;
      width: 400px;
      margin: auto;
    }

    .loading-borders {
      animation: spinner 20s linear infinite;
    }

    .camera-oval-inner {
      border-radius: 50%;
      overflow: hidden;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate3d(0, 0, 0);

      .face-frame {
        position: absolute;
        z-index: 10;
      }

      video {
        height: 100%;
        transform: rotateY(180deg);
      }
    }
  }

  .buttons {
    margin-top: 20px;

    button {
      color: #fff;
      width: 219px;
      height: 50px;
      border-radius: 11px;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.14);
      font-size: 18px;
      font-weight: bold;

      &.try-again {
        background-color: #3cb0f7;
        margin-right: 20px;
      }

      &.register {
        background-color: #21273b;
      }
    }
  }
}

.no-style {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-shadow: none;
}

.phone-form {
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #21273b;
  }

  input {
    @extend .no-style;
    display: block;
    width: 339px;
    height: 78px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: #edf2f6;
    text-align: center;
    padding: 0 10px;
    margin-bottom: 40px;

    font-size: 30px;
    font-weight: bold;

    &::placeholder {
      opacity: 0.2;
      font-size: 30px;
      color: #21273b;
    }
  }

  button {
    color: #fff;
    margin-top: 100px;
    width: 215px;
    height: 62px;
    object-fit: contain;
    border-radius: 31px;
    box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.15);
    background-color: #3cb0f7;
    font-size: 18px;
    font-weight: 900;
  }
}

.Modal {
  background-color: #fff;
  width: 500px;
  height: 500px;
  border-radius: 20px;
  position: relative;
  max-height: 800px;
}

.Overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: to-rgba($darkBlue, 0.9);
  z-index: 9999;
}
