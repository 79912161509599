.interview {
  color: #fff;
  text-align: center;
  height: calc(100vh - 120px);
  width: 50vw;
  min-width: 50vw;
  position: relative;

  .stream {
    height: 100%;
    position: relative;

    .header {
      margin-top: auto;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.75)
      );
      position: absolute;
      z-index: 200;
      width: 100%;
      top: 0;
      text-align: left;

      .connected-status {
        font-family: 'CircularStd-Book', sans-serif;
        &-icon {
          width: 9px;
          height: 9px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 10px;

          &.connected {
            background-color: #0cd5a2;
          }

          &.not-connected {
            background-color: #ff5c6f;
          }
        }
      }

      small,
      .interview-code,
      .connected-status {
        display: block;
        position: relative;
        left: 20px;
      }

      .interview-code {
        margin-top: 20px;
        border-radius: 3px;
        background-color: rgba(#fff, 0.2);
        padding: 15px 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        p {
          display: inline-block;
          margin: 0;
        }

        img {
          margin-left: 10px;
          position: relative;
          top: 1px;
        }
      }
    }

    .loading-circle {
      top: 100px;

      h2 {
        font-size: 26px;
      }

      p {
        font-size: 16px;
      }
    }

    .circle {
      background-image: url('../../../assets/img/spinner.svg');
      background-repeat: no-repeat;
      background-color: rgba(255, 255, 255, 0.1);
      background-size: contain;
      border-radius: 50%;
      height: 15em;
      margin: 0 auto;
      padding: 2em;
      width: 15em;
    }

    .circle.error {
      background-color: rgba(0, 0, 0, 0.6);

      h1,
      h2 {
        color: #f94c6a;
      }
    }

    .loading {
      display: flex;
      align-items: center;
    }

    .connecting .notice {
      bottom: 3em;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    .OTSubscriberContainer {
      position: inherit;
      z-index: 1;
    }

    .OTPublisherContainer {
      width: 219px !important;
      height: 219px !important;
      border-radius: 0;
      border: 5px solid #fff;
      bottom: 1em;
      left: 1em;
      position: absolute;
      z-index: 99999;
    }
  }
}

.modal {
  min-height: 430px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close-modal {
    position: absolute;
    top: 0.8em;
    right: 0.8em;
    font-size: 1em;
    color: white;
    font-weight: bold;
    border: none;
    background: transparent;
  }

  .modal-header {
    min-height: 70px;
    width: 100%;
    background-color: #20263d;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    width: 100%;
    min-height: 360px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    input {
      width: 339px;
      height: 70px;
      border-radius: 6px;
      background-color: #edf2f6;
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      border: none;
    }

    button {
      width: 226px;
      height: 65px;
      border-radius: 32px;
      color: white;
      font-size: 21px;
      background-color: #00b2fd;
      border: none;
    }
  }
}

.alerts {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  z-index: 201;

  button {
    border: none;
    background: none;
    img {
      height: 40px;
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.5;
      img {
        cursor: unset;
      }
    }
  }
}
