.logical-operator-wrapper {
  label {
    cursor: pointer;
    color: #21273b;
    input {
      display: none;
      &:checked ~ span {
        border-color: #3cb0f7;
        background-color: #3cb0f7;
        color: white;
        -webkit-font-smoothing: antialiased;
      }
    }
    span {
      font-size: 11px !important;
      padding: 4px 13px !important;
      border-radius: 7px;
      &:hover {
        background-color: #dedfe2;
      }
    }
  }
  &.selected {
    background-color: #dedfe2;
  }
}