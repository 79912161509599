.login-modal {
  text-align: center;

  * {
    box-sizing: border-box;
  }

  button {
    border: none;
  }

  h1 {
    font-size: 26px;
    font-weight: bold;
    color: #21273b;
    padding-top: 15px;

    &.has-error {
      color: #ff5c6f;
    }
  }

  .camera-oval-outer {
    width: 365px;
    height: 365px;
    margin: 0 auto;
    border: solid 5.6px #21273b;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    transform: translate3d(0, 0, 0);

    &.no-borders {
      transform: none;
      border: none;
    }

    &.has-error {
      border-color: #ff5c6f;
    }

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .borders {
      position: absolute;
      left: 0;
      margin: auto;
      right: 0;
      width: 390px;
      border-radius: 50%;
      z-index: 10;
    }

    .loading-borders {
      animation: spinner 20s linear infinite;
      z-index: 10;

      &.success {
        animation: none;
      }
    }

    .camera-oval-inner {
      border: 15px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate3d(0, 0, 0);
      min-width: 365px;
      min-height: 365px;
      max-width: 365px;
      max-height: 365px;

      .face-frame {
        position: absolute;
        z-index: 10;
      }

      video {
        height: 100%;
        transform: rotateY(180deg);
      }
    }
  }

  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      color: #fff;
      width: 219px;
      height: 50px;
      border-radius: 11px;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.14);
      font-size: 18px;
      font-weight: bold;

      &.try-again {
        background-color: #3cb0f7;
      }

      &.register {
        margin-left: 20px;
        background-color: #21273b;
      }
    }
  }
}

.phone-form.login-modal {
  height: 260px;
  justify-content: space-around;

  label {
    margin-bottom: 0;
  }

  .error-label {
    color: #ff637b;
  }

  input {
    margin-top: 0;
    margin-bottom: 0;

    &.error-input {
      border: solid 5px #ff637b;
    }
  }

  button {
    margin-top: 0;

    &.disabled {
      background-color: #bdbdbd;
    }
  }
}
