// This file contains helpers- base css variables which will be used in other scss files

// colors
$black: #000000;
$darkerBlue: #1c2237;
$darkBlue: #21273b;
$lightDarkBlue: #20263D;
$paleDarkBlue: #3e3f42;
$dividerGrey: #2e2e33;
$lightGrey: #fbfbfd;
$white: #ffffff;
$lightOffWhite: #eaedf3;
$offWhite: #d1d2d6;
$grey: #9ea0a5;
$grey2: #878794;
$darkGrey: #6b6c6f;
$darkGrey2: #3e3f42;
$lightBlue: #009eff;
$checkedBlue: #1665d8;
$blue: #2e98da;
$linkBlue: #00b2fd;
$lighterBlue: #3cb0f7;
$redWarning: #e6492d;
$warning: #fbba33;
$orangeWarning: #f6ab2f;
$green: #36ad46;

$black-opaque: rgba(0, 0, 0, 0.9);
$grey-100: rgba(112, 111, 111, 1);
$grey-200: rgba(216, 216, 216, 1);
$grey-300: rgba(220, 220, 220, 1);
$grey-400: rgba(240, 240, 240, 1);
$grey-500: rgba(247, 247, 247, 1);
$cyan: rgba(33, 160, 210, 1);
$cyan-opaque: rgba(33, 160, 210, 0.9);
$cyan-hover: rgba(119, 195, 225, 1);
$signal-orange: rgba(243, 149, 0, 1);

//fonts
$defaultFont: 'CircularStd-Medium', 'BentonSans', Helvetica, Arial, sans-serif;
$CircularStd-Black: 'CircularStd-Black', sans-serif;
$CircularStd-Bold: 'CircularStd-Bold', sans-serif;
$CircularStd-Medium: 'CircularStd-Medium', sans-serif;
$CircularStd-Book: 'CircularStd-Book', sans-serif;
$CircularStd-BookItalic: 'CircularStd-BookItalic', sans-serif;
$B612Mono-Regular: 'B612Mono-Regular', sans-serif;
