.loading {
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    margin: 0 auto;
    opacity: 0.5;
    background: #000;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &.show {
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 400ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }

    .loading-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform: rotateZ(45deg);
        margin-left:-20px;
        margin-top: -40px;
        width: 40px;
        height: 40px;
    }

    .loading-box .loading-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        transform: scale(1.1);

    }

    .loading-box .loading-cube:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 188, 212);
        animation: loading-cube-angle 2.4s infinite linear both;
        transform-origin: 100% 100%;
    }

    .loading-box .loading-cube2 {
        transform: scale(1.1) rotateZ(90deg);
    }

    .loading-box .loading-cube3 {
        transform: scale(1.1) rotateZ(180deg);
    }

    .loading-box .loading-cube4 {
        transform: scale(1.1) rotateZ(270deg);
    }

    .loading-box .loading-cube2:before {
        animation-delay: 0.3s;
    }

    .loading-box .loading-cube3:before {
        animation-delay: 0.6s;
    }

    .loading-box .loading-cube4:before {
        animation-delay: 0.9s;
    }

    @-webkit-keyframes loading-cube-angle {
        0%, 10% {
            transform: perspective(140px) rotateX(-180deg);
            opacity: 0;
        }
        25%, 75% {
            transform: perspective(140px) rotateX(0deg);
            opacity: 1;
        }
        90%, 100% {
            transform: perspective(140px) rotateY(180deg);
            opacity: 0;
        }
    }

    @keyframes loading-cube-angle {
        0%, 10% {
            transform: perspective(140px) rotateX(-180deg);
            opacity: 0;
        }
        25%, 75% {
            transform: perspective(140px) rotateX(0deg);
            opacity: 1;
        }
        90%, 100% {
            transform: perspective(140px) rotateY(180deg);
            opacity: 0;
        }
    }
}
