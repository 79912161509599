.notification-bar {
  font-size: 14px;
  color: white;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;

  &.error {
    background-color: #e6492d;
    padding: 0.4rem 0;
  }

  &.success {
    background-color: #0cd5a2;
    padding: 0.4rem 0;
  }

  &.visible {
    opacity: 1;
    max-height: 32px;
  }
}
