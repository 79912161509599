.password-input-wrapper {
  display: flex;
  position: relative;

  input {
    margin: 0 !important;
    padding-right: 35px !important;
  }

  img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;

    &.open {
      opacity: 1;
    }
  }
}
