.rules-select-wrapper, .rules-group-select-wrapper {
  display: inline-flex;
  input,
  select {
    height: 44px;
    border: 1px solid rgba(33, 39, 59, 0.15);
    border-radius: 7px;
    outline: none;
    font-weight: bold;
    font-size: 16px;
    color: #21273b;
    padding: 0 12px;
    margin: 0 0.5rem;
    &:disabled{
      background-color: rgba(33, 39, 59, 0.15);
    }
  }
  input {
    max-width: 128px;
  }
  select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239EA0A5'%3E%3Cg%3E%3Cpath d='M3 0L6 3 0 3z' transform='translate(-248 -436) translate(248 436)'/%3E%3Cpath d='M3 7L6 10 0 10z' transform='translate(-248 -436) translate(248 436) matrix(1 0 0 -1 0 17)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 0.5rem);
    background-position-y: 16px;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 1.5rem;
    max-width: 210px;
  }
}