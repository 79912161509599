.user-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;

  button {
    color: #fff;
    border: none;

    &:hover{
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;

    h1 {
      color: #21273b;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.62px;
    }

    .links {
      display: flex;
      align-items: flex-end;
      height: 100%;
      padding-right: 40px;

      button {
        padding: 20px 40px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.62px;
        text-align: center;
        color: rgba(#21273b, 0.5);
        background-color: transparent;
        margin: 0;

        &.active {
          border-top-left-radius: 11px;
          border-top-right-radius: 11px;
          background-color: #dedfe2;
          color: #21273b;
        }
      }
    }

    .logo {
      padding: 25px 40px;
    }
  }

  .green {
    color: #0cd5a2;
  }

  .dashboard-container {
    background-color: rgba(33, 39, 59, 0.15);
    flex: 1;
    padding: 45px 40px;
    // width: 100%;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    color: #21273b;
    overflow: scroll;
    
    table {
      border-collapse: collapse;
      width: 100%;
      background-color: #fff;
      
      td, th {
        text-align: left;
        vertical-align: middle;
        min-width: 90px;
        &:nth-last-child(2){
          width: 200px;
        }
      }

      tr.table-header {
        background-color: #21273b;
        color: #fff;
        font-size: 13px;
        
        th {
          padding: 10px 0;
        }

        th:first-child {
          border-top-left-radius: 4px;
        }

        th:last-child {
          border-top-right-radius: 4px;
        }
      }

      tr.row {
        font-weight: bold;
        height: 69px;
      }

      tr.new-user {
        background-color: rgba(#21273b, 0.06);

        input {
          border-radius: 7px;
          background-color: rgba(#21273b, 0.15);
          border: 0;
          height: 35px;
          //width: 165px;
          width: 90%;
          padding: 0 12px;
          font-weight: bold;
          text-align: center;
          font-size: 16px;
        }
      }

      td.avatar, td.actions {
        text-align: center;
      }

      .error{
        color: #fa6062;
        max-width: 200px;
        font-size: 13px;
      }

      button {
        border-radius: 15.5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        background-color: #3cb0f7;
        padding: 8px 0px;
        font-size: 12px;
        font-weight: 900;
        width: 120px;

        &.submit {
          background-color: #0cd5a2;
        }

        &.delete {
          background-color: #fa6062;
        }
      }
    }
  }
}