* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:link,
a:visited {
  text-decoration: none;
  color: #fff;
}

.app-wrapper {
  min-height: 100vh;
  display: flex;
  max-width: 100vw;
  margin: auto;
  background-color: #fbfbfd;
  color: black;
}

.main-content {
  width: calc(100% - 270px);
}

.not-found {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.nf-h1 {
  color: black;
}

@media screen and (max-width: 1200px) {
  .main-content {
    width: 100%;
    padding-top: 30px;
  }
}
