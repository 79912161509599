html * {
  outline: 0 !important;
}

html {
  background-color: #1c1f28;
  overflow-x: hidden;
}

body {
  padding: 0;
  margin: 0;
  font-family: $defaultFont;
  overflow-x: hidden;

  * {
    box-sizing: border-box;
  }
}

.hidden {
  display: none;
}

// Fix autocomplete color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: $white !important;
  color: #555 !important;
  -webkit-box-shadow: 0 0 0 1000px $white inset !important;
  -webkit-text-fill-color: #555555 !important;
}

//links
a {
  color: #949ea8;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// Paragraph
p {
  font-size: 16px;
  line-height: 1.33;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: white;
}
