.modal-text{
  color: #1c2237;
}
.modal-buttons-container{
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 2rem;
  .modal-button{
    border: none;
    border-radius: 11px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.14);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    height: 50px;
    text-align: center;
    width: 219px;
    &:hover{
      opacity: 0.7;
      cursor: pointer;
    }
    &.deny{
      background-color: #e6492d;
    }
    &.confirm{
      background-color: #3cb0f7;
    }
  }
}
