.rules {
  background-color: white;
  height: 100%;
  min-height: 100%;
  .rules-actions {
    background-color: #21273b;
    color: #fff;
    font-size: 13px;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  button {
    border-radius: 15.5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: #3cb0f7;
    padding: 8px 0;
    font-size: 12px;
    font-weight: 900;
    width: 120px;
    -webkit-font-smoothing: antialiased;

    &:hover {
      cursor: pointer;
    }

    &.submit {
      background-color: #0cd5a2;
    }
    &.destructive {
      background-color: #e6492d;
    }
  }

  input[type='text'] {
    border-radius: 7px;
    background-color: white;
    border: 1px solid rgba(33, 39, 59, 0.15);
    height: 44px;
    width: 300px;
    padding: 0 12px;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0.5rem;
    &:disabled {
      background-color: rgba(33, 39, 59, 0.15);
    }
  }

  label {
    &.input-label {
      color: #1c2237;
    }
  }

  .rule-container {
    height: calc(100% - 50px);
    .rules-table {
      padding: 2rem;
      height: 100%;
      width: 100%;
      .single-rule {
        width: max-content;
        margin-bottom: 0.3rem;
        display: flex;
        align-items: center;
        .label {
          color: #1c2237;
          margin-right: 1rem;
        }
        .rule-name {
          margin-left: 0.3rem;
          margin-right: 1rem;
          color: white;
          border-left: 2px solid #1c2237;
          background-color: rgba(60, 176, 247, 0.77);
          border-radius: 0 7px 7px 0;
          padding: 1.3rem 1rem;
          -webkit-font-smoothing: antialiased;
          display: inline-flex;
          align-items: center;
          img {
            margin-left: 0.8rem;
            &:hover {
              cursor: pointer;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
        img {
          margin-right: 0.4rem;
          &:hover {
            cursor: pointer;
          }
        }
        .info-icon {
          position: relative;
          display: inline-flex;
          .overlay {
            width: max-content;
            max-width: 1000px;
            text-align: left;
            position: absolute;
            top: -20px;
            left: 100%;
            background: #fff;
            border: 1px solid #dedfe2;
            border-radius: 4px;
            padding: 10px;
            display: none;
            opacity: 0;
            transition: opacity 0.3s;
            z-index: 1;
            .content {
              font-size: 14px;
              font-weight: 100;
              font-family: 'Courier New', monospace;
              .if {
                color: #007acc;
                font-weight: bold;
              }
              .then {
                color: #28a745;
                font-weight: bold;
              }
            }
          }
          &:hover {
            .overlay {
              display: block;
              opacity: 1;
            }
          }
        }
      }
      &.none {
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
          color: #1c2237;
        }
      }
    }
    .rule-creation {
      height: 0;
      background-color: white;
      overflow: hidden;
      transition: height 0.3s ease;
      &.visible {
        width: 100%;
        height: 100%;
        padding: 2rem;
        display: flex;
        .rule-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          .rule-name {
            margin-bottom: 1.5rem;
            max-width: max-content;
          }
          .rule {
            display: flex;
            align-items: flex-start;
            .left-side {
              display: flex;
              flex-direction: column;
              flex: 2;
              p {
                color: #1c2237;
                margin-right: 1rem;
              }
              input[type='text'] {
                width: 200px;
              }
              .operators {
                display: flex;
                padding: 5px 0 10px 25px;
                &.selected {
                  background-color: #21273b26;
                }
              }
              .expression-row {
                display: flex;
                align-items: center;
                .suffix-select {
                  select {
                    min-width: 176px;
                  }
                }
              }
              .operands {
                padding: 12px;
                &.selectedPrev {
                  background-color: #21273b26;
                  border-radius: 7px 7px 0 0;
                }
                &.selectedNext {
                  background-color: #21273b26;
                  border-radius: 0 0 7px 7px;
                }
              }
            }
            .right-side {
              display: flex;
              margin-left: 3rem;
              flex: 1;
              //padding: 12px;
              padding: 26px;
              background-color: #3cb0f71a;
              border-radius: 8px;
              .checkbox {
                color: #1c2237;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin-bottom: 0.3rem;
                input[type='checkbox'] {
                  position: relative;
                  width: 20px;
                  height: 20px;
                  appearance: none;
                  outline: none !important;
                  border-radius: 5px;
                  background-color: #fff;
                  border: 1px solid rgba(33, 39, 59, 0.15);
                  &:disabled {
                    background-color: rgba(33, 39, 59, 0.15);
                    &:checked::before {
                      background-color: rgba(33, 39, 59, 0.45);
                      border-color: #fff;
                    }
                  }
                  &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 14px;
                    height: 14px;
                    border-radius: 5px;
                  }
                  &:checked::before {
                    background-color: #1c2237;
                    border-color: #fff;
                  }
                }
              }
              .ttl-input {
                input {
                  margin-left: 0;
                  max-width: 50px;
                  margin-top: 0.4rem;
                }
              }

              p {
                color: #1c2237;
                margin-right: 1rem;
              }
              .rule-result {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }
          }
        }
      }
    }
  }
}
