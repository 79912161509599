@import 'index';

.login-page {
  @extend %session-info-flex;
  padding: 1.56rem 0;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  background: $darkBlue;

  .logo-incode {
    text-align: center;
    background: $darkBlue;
    padding-bottom: 40px;
    color: $white;
    img {
      margin-bottom: 10px;
    }
  }
  .login-inner {
    max-width: 445px;
    width: 100%;
    background: $white;
    padding: 35px 40px;
    border-radius: 4px;
    margin-bottom: 25px;

    .login-form {
      h2 {
        color: $paleDarkBlue;
        padding-bottom: 15px;
        .login-email {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 370px;
          display: inline-block;
        }
      }
      .welcome {
        font-family: $CircularStd-Medium;
        font-size: 18px;
        color: $grey;
      }
      .password-success {
        display: flex;
        justify-content: center;
        margin: 40px auto;
      }

      form {
        padding-top: 27px;
        .code-content {
          label {
            width: 100%;
            margin-bottom: 16px;
          }
          label {
            @extend %form-label;
            margin-bottom: 8px;
            margin-right: 15px;
            span {
              @extend %form-label;
              margin-bottom: 8px;
              display: inline-block;
              width: 100%;
              &.error-message {
                @extend %error-message;
                width: auto;
                float: right;
                margin-bottom: 0;
              }
            }
          }
          input {
            padding: 0;
            margin-bottom: 0;
          }
        }
      }

      .green-lg {
        margin-top: 20px;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        color: $white;
        font-family: $CircularStd-Medium;
        font-size: 14px;
        border: solid 1px #2d9c3c;
        border-radius: 4px;
        background-image: linear-gradient(to top, #34aa44, #38b249);
        box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1),
          inset 0 2px 0 0 rgba(255, 255, 255, 0.06);

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    label,
    input {
      width: 100%;
      margin-bottom: 16px;
    }
    label {
      @extend %form-label;
      margin-bottom: 8px;
      margin-right: 15px;
      span {
        @extend %form-label;
        margin-bottom: 8px;
        display: inline-block;
        width: 100%;
        &.error-message {
          @extend %error-message;
          width: auto;
          float: right;
          margin-bottom: 0;
        }
      }
    }
    input {
      @extend %form-input;
      &.error-input {
        @extend %form-input-error;
      }
    }
  }
  .outer-link {
    margin-bottom: 10px;
    display: flex;
    span {
      color: $grey;
      font-family: $CircularStd-Medium;
      font-size: 14px;
      margin-right: 10px;
    }
    p {
      color: $checkedBlue;
      font-family: $CircularStd-Medium;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .error {
    margin-top: 10px;
    color: $redWarning;
    text-align: center;
  }
  .msg {
    margin-top: 10px;
    color: $checkedBlue;
    text-align: center;
  }
}
