.picture {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 3.5px #ffffff;
    overflow: hidden;

    img {
        width: 100%;

        &.ine  {
            width: 80%;
            height: auto;
        }
    }
}
