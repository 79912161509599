.manual-review {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  * {
    box-sizing: border-box;
  }

  &.empty {
    justify-content: flex-start;
  }

  header {
    display: grid;
    grid-template-columns: repeat(2, minmax(180px, auto));
    align-items: center;
    padding: 10px;
    border: solid 1px #979797;
    background-color: #ffffff;
    justify-content: flex-end;
    button.languaje,
    button.btn-logout {
      padding: 5px;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: transparent;
      box-shadow: none;
      margin: 0 55px 0 0;
      font-size: 22px;
      color: $darkerBlue;
      cursor: pointer;

      &.selected {
        border-bottom: 3px solid $darkBlue;
      }

      &.disabled {
        opacity: 0.3;
      }

      .flag {
        margin-right: 10px;
      }
    }
    button.btn-logout {
      margin-right: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  h1 {
    text-align: center;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    background-color: $darkerBlue;
    flex: 1 1;
    grid-gap:0.5rem;

    label {
      color: black;
    }

    @media only screen and (min-width: 768px) {
      grid-template-columns: 5fr 6fr;
    }
  }
  .images{
    display: flex;
    align-items: center;
    padding: 0 2rem;
    background-color: $lightDarkBlue;
  }
  .ids {
    display: grid;
    align-items: center;
    .id-type{
      text-transform: capitalize;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      -webkit-font-smoothing: antialiased;
    }
    &.horizontals-with-selfie {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.5rem;
      grid-template-rows: auto auto;
      align-content: start;
      justify-content: center;
      > *:nth-child(1),
      > *:nth-child(2) {
        grid-column: 1;
      }
      > *:nth-child(3) {
        grid-column: 2;
        grid-row: 1 / span 2;
      }
    }
    &.horizontal-single{
      grid-template-rows: auto;
      justify-content: center;
    }
    &.vertical-single {
      grid-template-rows: auto;
      justify-content: center;
      max-width:500px
    }
    &.horizontal-with-selfie{
      grid-template-columns: 2fr 1fr;
      grid-template-rows: repeat(1, 1fr);
      gap:10px;
    }

    &.vertical-multiple-without-video {
      gap: 10px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
      justify-content: center;
      align-items: center;
      @media only screen and (min-width: 768px) {
        padding-top: 5%;
        padding-bottom: 5%;
      }
    }

    &.multiple-with-selfie {
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
      justify-content: center;
      align-items: center;
      @media only screen and (min-width: 768px) {
        padding-top: 25%;
        padding-bottom: 25%;
      }
    }
    &.different-orientation{
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    .image-wrapper {
      position: relative;
      border-radius: 9.2px;
      border: solid 4px #ffffff;
      max-height: 100%;
      z-index: 1;
      figure {
        max-width: 100%;
        border-radius: 9.2px;
        object-fit: contain;
        position: relative;
        //max-height: 100%;
        max-height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:before {
        position: absolute;
        background: transparent;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }
    }
  }

  .main-content-mc {
    display: grid;
    grid-template-columns: 1fr;
    grid-area: auto;
    align-items: center;
    max-height: 1000px;
    background-color: $darkerBlue;
    padding: 30px 2rem 30px 2rem;
  }

  .timer {
    display: flex;
    background-color: #ffffff;
    width: 180px;
    height: 60px;
    box-shadow: 0 0 0 8px #747886;
    border-radius: 10px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;

    span {
      font-size: 30px;
      font-weight: bold;
    }

    &.red {
      box-shadow: 0 0 0 8px #6a384c;
      background-color: #ff5c6f;
      color: #fff;
    }
  }
  .timer-container {
    display: flex;
    justify-content: space-between;
    .label{
      font-size: 18px;
      color: rgba(255, 255, 255, 0.4);
      -webkit-font-smoothing: antialiased;
      display: block;
      span{
        font-weight: bold;
        color: #ffffff;
        display: inline-block;
        margin-left: 0.4rem;
      }
    }
    .timer{
      margin-right: 0;
    }
  }
  .questions {
    width: 100%;
    grid-template-rows: auto;
    grid-gap: 20px;
    display: grid;
    margin: 40px 0;

    @media only screen and (min-width: 768px) {
      margin: 0;
    }
  }

  .question {
    display: grid;
    border-radius: 16px;
    background-color: $darkBlue;
    padding: 14px 19px 14px 29px;
    align-items: center;
    grid-template-columns: 2fr 1fr;

    label {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      flex: 1;
      -webkit-font-smoothing: antialiased;
    }
    .MuiIconButton-root.Mui-disabled {
      background-color: #ffffff;
      opacity: 0.8;
    }
  }

  .actions {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      border: 0;
      width: 150px;
      height: 48px;
      border-radius: 8px;
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      font-weight: bold;
      color: #fff;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover:enabled {
        color: rgba(255, 255, 255, 0.85);
        opacity: 0.8;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 1;
      }
      &.primary {
        background-color: $green;
        margin-right: 2rem;
      }
      &.cancel {
        background-color: #ff5c6f;
      }
    }
  }

  .radio-group {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 2fr;
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }

  .control-label {
    color: #fff;
    font-size: 12px;
  }
}
